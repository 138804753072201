import React from "react";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>개인정보처리방침</h1>
      <p>
        『Mira』는(이하 ‘의원’ 이라 함) 귀하의 개인정보보호를 매우 중요시하며,
        [개인정보보호법]을 준수하고 있습니다.
        <br />
        병원은 개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한
        용도와 방식으로 이용되고 있으며 개인정보를 위해 어떠한 조치가 취해지고
        있는지 알려드립니다.
        <br />
        <br />
        1. 수집하는 개인정보의 항목 및 수집방법
        <br />
        2. 개인정보의 수집 및 이용 목적
        <br />
        3. 개인정보의 보유 및 이용기간 및 파기절차 및 파기방법
        <br />
        4. 이용자 및 법정대리인의 권리와 그 행사방법
        <br />
        5. 동의철회 방법
        <br />
        6. 개인정보의 제공 및 위탁
        <br />
        7. 개인정보의 제3자 제공 및 위탁
        <br />
        8. 개인정보 보호책임자
        <br />
        9. 개인정보의 안전성 확보조치
        <br />
        10. 회원탈퇴
        <br />
        11. 정책 변경에 따른 공지의무
        <br />
      </p>
      <h2>1. 수집하는 개인정보의 항목 및 수집방법</h2>
      <p>
        의원은 인터넷 회원가입 및 예약 시 서비스 이용을 위해 필요한 최소한의
        개인정보만을 수집합니다. 진료를 위한 필수정보는 의료법에 따라 별도의
        동의 없이 진료카드를 작성 통해 수집하며, 진료 외의 추가 서비스(광고성)를
        위해서는 별도의 수집·이용 동의서를 작성하셔야 하며, 동의서를 작성하지
        않더라도 진료에는 전혀 불이익이 없습니다.
        <br />- 필수항목: 성명, 생년월일, 성별, 주소, 연령, 연락처, 휴대전화,
        이메일, 병원등록번호, 서비스신청여부 (카카오 로그인 시 필수(이메일,
        전화번호), 선택(이름))
        <br />- 건강정보: 내원정보, 상병정보, 처방정보, 진료정보
        <br />
        <br />
      </p>
      <h2>2. 개인정보의 수집 및 이용 목적</h2>
      <p>
        ① 필수
        <br />- 진료정보: 진단 및 치료를 위한 진료서비스와 청구, 수납 및 환급
        등의 원무 서비스 제공
        <br />- 예약정보: 진료 예약 및 예약조회 등 기타 서비스 이용에 따른 본인
        확인 절차에 이용
        <br />
        문자 및 SNS를 통한 병원소식, 질병정보 등의 안내, 설문조사
        <br />- 기타: 신규 서비스 개발을 위한 방문 고객 분석자료 작성에 이용
        <br />
        외부 수탁검사 의뢰를 위한 기초자료
        <br />- 소비자 기본법 제52조에 의거한 소비자 위해 정보 수집
        <br />
        법령 및 의원 이용약관을 위반하는 이용자에 대한 이용 제한 조치, 부정 이용
        행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및
        제재, 개인정보 도용 및 부정사용 방지, 분쟁조정을 위한 기록 보존,
        민원처리 등<br />② 선택
        <br />- 마케팅 및 홍보: 병원 소식, 병원 시술 및 이벤트, 개인 맞춤형 광고
        및 마케팅, 화장품 등 광고·홍보성 정보 전달
        <br />
        <br />
      </p>
      <h2>3. 개인정보의 보유 및 이용기간 및 파기절차 및 파기방법</h2>
      <div>[보유 및 이용기간]</div>
      <p>
        - 의원은 수집된 고객의 개인정보를 환자의 명부 5년, 진료기록부를 보관하는
        법정 기간(10년)동안만 보유하며 그 이후는 DB에서 삭제. 다만,
        채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지
        <br />- 정보제공자가 개인정보 삭제를 요청할 경우 즉시 삭제
        <br />
        단, 수집 목적 또는 제공받는 목적이 달성된 경우에도 상법 등 기타 법령의
        구정에 다라 보존할 필요성이 있는 경우, 개인정보를 보유할 수 있음
        <br />
        * 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의
        소비자보호에 관한 법률)
        <br />* 신용정보의 수집/처리 및 이용 등에 관한 기로: 3년(신용정보의 이용
        및 보호에 관한 법률)
        <br />* 본인 확인에 관한 기록: 6개월 (정보통신망 이용촉진 및 정보보호
        등에 관한 법률)
        <br />
        <br />
        <div>[파기절차 및 파기방법]</div>
        <p>
          - 파기절차: 법정 보유기간 후 파기방법에 의하여 즉시 파기
          <br />- 파기방법: 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
          없는 기술적 방법을 사용하여 삭제하고 종이에 출력된 개인정보는 분쇄기로
          분쇄하거나 소각하여 파기
          <br />- 고객관리 서비스 위탁 종료 시: 수탁자는 2주 이내에 관련파일 및
          DB데이터의 영구적 파기
          <br />
          <br />
        </p>
      </p>
      <h2>4. 이용자 및 법정대리인의 권리와 그 행사방법</h2>
      <p>
        미성년자의 예약은 미성년자가 이해하기 쉬운 평이한 표현으로 작성된 별도의
        양식을 통해 이루어지고 있으며 개인정보 수집 시 반드시 법정대리인의
        동의를 구하고 있습니다.
        <br />
        의원은 법정대리인의 동의를 받기 위하여 미성년자로부터 법정대리인의
        성명과 연락처 등 최소한의 정보를 수집하고 있으며, 개인정보취급방침에서
        규정하고 있는 방법에 따라 법정대리인의 동의를 받고 있습니다.
        <br />
        미성년자의 법정대리인은 미성년자의 개인정보에 대한 열람, 정정 및 삭제를
        요청할 수 있습니다. 미성년자의 개인정보를 열람·정정, 삭제하고자 할
        경우에는 회원정보수정을 클릭하여 법정대리인 확인 절차를 거치신 후
        미성년자의 개인정보를 법정대리인이 직접 열람·정정, 삭제하거나,
        개인정보보호책임자로 서면, 전화, 또는 Fax등으로 연락하시면 필요한 조치를
        취합니다.
        <br />※ 법에 의해 보관이 의무화된 개인정보는 요청이 있더라도 보관 기간
        내에 수정·삭제할 수 없음.
        <br />
      </p>
      <h2>5. 동의철회 방법</h2>
      <p>
        귀하는 온라인예약 시 선택사항의 개인정보의 수집·이용 및 제공에 대해
        동의하신 내용을 언제든지 철회하실 수 있습니다. 개인정보보호책임자로
        서면, 전화 또는 Fax 등으로 연락하시면 지체 없이 귀하의 개인정보를
        파기하는 등 필요한 조치를 하겠습니다.{"\n"}
      </p>
      <h2>6. 개인정보의 제공 및 공유</h2>
      <p>
        의원은 귀하의 동의가 있거나 관련법령의 규정에 의한 경우를 제외하고는
        어떠한 경우에도 개인정보의 수집 및 이용목적에서 고지한 범위를 넘어
        귀하의 개인정보를 이용하거나 타인 또는 타기업ㆍ기관에 제공하지 않습니다.
        <br />- 국민건강보험법에 의해 건강보험심사평가원에 요양급여비용 청구를
        위한 진료기록 제출
        <br />- 통계작성ㆍ학술연구를 위하여 필요한 경우 특정 개인을 알아볼 수
        없는 형태로 가공하여 제공
        <br />- 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
        제출 등<br />
      </p>
      <h2>7. 개인정보의 제3자 제공 및 위탁</h2>
      <p>
        의원은 인터넷예약 및 문자 알림서비스 업무를 아래와 같이 개인정보를
        위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될
        수 있도록 필요한 사항을 규정하고 있습니다.
        <br />
        의원의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
        <br />
        <br />
        수탁업체 : 메먼트 AI
        <br />
        위탁업무 내용:홈페이지 운영 및 관리, DB 관리 및 저장, 병원, 시술 및
        이벤트, 개인 맞춤형 광고 및 마케팅, 화장품, 화장품 이벤트 등 광고성 정보
        전달
        <br />
        개인정보 보유 및 이용기간:위탁계약 종료 시 까지
        <br />
      </p>
      <h2>8. 개인정보보호 책임자</h2>
      <p>
        이름:이재민
        <br />
        직위:대표
        <br />
        소속:메멘토에이아이
        <br />
        {/* TODO: 전화번호 확인 후 수정하기 */}
        전화번호:1544-2994
        <br />
      </p>
      <h2>9. 개인정보의 안전성 확보조치</h2>
      <p>
        의원은 이용자의 개인정보보호를 위한 기술적 대책으로서 여러 보안장치를
        마련하고 있습니다. 이용자께서 제공하신 모든 정보는 방화벽 등 보안장비에
        의해 안전하게 보호/관리되고 있습니다.
        <br />
        또한 병원은 이용자의 개인정보보호를 위한 관리적 대책으로서 이용자의
        개인정보에 대한 접근 및 관리에 필요한 절차를 마련하고, 이용자의
        개인정보를 처리하는 인원을 최소한으로 제한하여 지속적인 보안교육을
        실시하고 있습니다. 또한 개인정보를 처리하는 시스템의 사용자를 지정하여
        사용자 비밀번호를 부여하여 이를 정기적으로 갱신하겠습니다.
      </p>
      <h2>10. 회원탈퇴</h2>
      <p>
        귀하는 언제든지 회원탈퇴를 요청할 수 있습니다. 회원탈퇴를 원하실 경우,
        서비스 내의 회원탈퇴 메뉴를 통해 탈퇴 절차를 진행할 수 있습니다.
        회원탈퇴 요청 시, 해당 계정에 대한 모든 개인정보는 지체 없이 삭제됩니다.
        단, 법령에 의해 보관이 필요한 경우 해당 정보를 일정 기간 동안 보관할 수
        있습니다.
      </p>
      <h2>11. 정책 변경에 따른 공지의무</h2>
      <p>
        이 개인정보처리방침은 2024년 04월 15일에 제정되었으며 법령·정책 또는
        보안기술의 변경에 따라 내용의 추가·삭제 및 수정이 있을 시에는 변경되는
        개인정보처리방침을 시행하기 최소 7일전에 병원 홈페이지를 통해 변경이유
        및 내용 등을 공지하도록 하겠습니다.
        <br />
        공고일자 : 2024년 07월 01일
        <br />
        시행일자 : 2024년 07월 01일
        <br />
      </p>
    </div>
  );
}

export default PrivacyPolicy;
