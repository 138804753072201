import React from "react";

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <h1>이용약관</h1>
      <p>
        Mira는 서비스를 이용하는 개인정보보호를 원칙으로 하며, 정보통신망 이용
        촉진 등에 관한 법률상의 개인정보보호 규정 및 정보 통신부가 제정한
        개인정보보호지침을 준수합니다. <br />
        이용약관을 통하여 저희 Mira는 이용자의 동의 없이 개인정보를 수집하지
        않으며, 개인정보가 어떻게 이용되고 개인정보보호가 어떻게 이루어지고
        있는지 알려줍니다. <br />
        회원가입을 통한 개인의 정보에 대해 이윤 추구 목적을 위해 양도하거나
        매매하지 않습니다. <br />
      </p>
      <h2>제1조 개인 정보 수집에 대한 동의</h2>
      <p>
        Mira의 회원가입을 원하실 경우 이용약관의 내용에 동의하였을 경우 개인정보
        수집에 동의한 것으로 인정됩니다. <br />
        Mira는 개인정보의 수집에 대한 회원탈퇴를 개인정보 수집 시와 동등한 방법
        및 절차로 행사할 수 있도록 필요한 조치를 하겠습니다. <br />
        <br />
      </p>
      <h2>제2조 목적</h2>
      <p>
        이 약관은 Mira가 제공하는 서비스 이용조건 및 절차에 관한 사항과 기타
        필요한 사항을 전기통신사업법 및 동법 시행령이 정하는 대로 준수하고
        규정함을 목적으로 합니다. <br />
        회원 가입 시 서비스 제공을 위한 본인 확인과 회원관리를 목적으로 합니다.{" "}
        <br />
        Mira가 제공하는 서비스(의학상담, 진료예약, 안내자료, 각종 행사 정보
        등)를 이행하기 위함입니다. <br />
        Mira는 개인정보보호와 관련하여 귀하가 의견과 불만을 제기할 수 있는
        창구를 개설하여 개인정보와 관련한 불만이 있으신 분은 Mira의 개인정보
        관리 책임자에게 의견을 주시면 접수 즉시 조치합니다. <br />
        <br />
      </p>
      <h2>제3조 약관의 효력과 변경</h2>
      <p>
        1. Mira는 이용자의 동의 없이 타인이나 타기타기관에 이용자의 정보를
        제공하거나 공유하지 않으며 보다 나은 서비스제공을 위해서 제휴사와 공유할
        경우 개별적으로 통보하여 동의를 얻게 되는 절차를 거치게 되며 귀하께서
        동의하지 않는 경우에는 제휴사와 공유하지 않습니다. <br />
        2. 이용자는 언제든지 본인의 신상정보를 확인할 수 있으며, 원하시면
        수정하거나 삭제하실 수 있습니다. <br />
        3. 귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공 받은
        목적이 달성되면 파기되고 계속 보유하여야 할 경우 이용자의 동의를
        얻습니다. <br />- 회원가입정보의 경우, 회원가입을 탈퇴하거나 회원에서
        제명된 때 <br />- 진료비 지급정보의 경우, 진료비의 완제일 또는
        채권소멸시효기간의 만료된 때 <br />- 진료정보의 경우, 의료 또는 서비스가
        인도되거나 제공된 때 <br />
        (단, 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 예외로
        합니다.) <br />
      </p>
      <h2>제5조 개인정보 유출 방지 대책</h2>
      <p>
        Mira는 이용자의 개인정보를 이용함에 있어 개인정보의 도난, 분실, 훼손되지
        않도록 안전성 확보를 위하여 다음과 같은 방법을 이용해 노력하고 있습니다.{" "}
        <br />
        <br />
        1. 귀하의 개인정보는 ID와 비밀번호에 의해 보호되며, 시스템관리자와
        본인에 의해서만 수정/삭제 가능하며, 파일이나 데이터 전송 시에는 암호화
        하도록 하여 별도의 보안기능을 통해 보호하고 있습니다. <br />
        2. Mira는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 이용자의 피해를
        방지하기 위해 조치를 취하고 있습니다. <br />
        3. Mira는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게
        전송할 수 있는 보안장치를 이용하고 있습니다. 해킹 등에 의해 귀하의
        개인정보가 유출되는 것을 방지하고 외부로부터의 침입을 차단하는 장치를
        이용하고 있습니다. <br />
        <br />
      </p>
      <h2>제 6조 회원의 의무사항</h2>
      <p>
        1. 회원은 본 약관에 규정하는 사항과 기타 Mira가 정한 제반 규정, 서비스
        이용안내 또는 공지사항 등 회사가 공지 또는 통지하는 사항 및 관계법령을
        준수하여야 하며, 기타 Mira의 업무에 방해가 되는 행위, Mira의 명예를 손상
        시키는 행위를 하여서는 안됩니다. <br />
        2. Mira가 관계법령 및 개인정보보호정책에 의하여 그 책임을 지는 경우를
        제외하고 관리소홀, 부정사용에 의하여 발생되는 결과에 대한 책임은
        회원에게 있습니다. <br />
        3. 회원은 서비스 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에
        근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의
        권리를 주장할 수 없습니다. <br />
        4. 회원은 회사 및 제3자의 지적 재산권을 침해해서는 안됩니다. <br />
        5. 회원은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는
        경우에 Mira는 회원의 서비스 이용제한 및 적법조치를 포함한 제재를 가할 수
        있습니다. <br />
        (1) 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위 <br />
        (2) 다른 이용자의 ID, 주민등록번호, 비밀번호, 이메일 주소 등의
        개인정보를 도용하는 행위 <br />
        (3) 이용자 ID및 제공받은 서비스를 타인과 거래하거나, 회사의 동의없이
        양도하는 행위 <br />
        (4) 타 관계법령에 위배되는 행위 <br />
      </p>
    </div>
  );
}

export default TermsOfService;
