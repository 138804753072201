import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="tabs">
            <Link to="/privacy">
              <button>개인정보처리방침</button>
            </Link>
            <Link to="/terms">
              <button>이용약관</button>
            </Link>
          </div>
        </header>
        <main className="App-content" style={{ padding: "0 20px" }}>
          <Routes>
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/" element={<Navigate to="/privacy" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
